import styled, { css } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const ComponentContainer = styled.div`
  width: 100%;
  max-width: 20rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary};
`

export const SelectionContainer = styled.div<{ $inverted: boolean }>`
  position: relative;

  width: 100%;
  height: ${Tokens.HeightInput};
  border-radius: ${Tokens.RadiusInput};
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  color: ${Tokens.ColorTextPrimary};
  background-color: ${Tokens.ColorBackground};
  cursor: pointer;

  &:hover,
  &:focus-within {
    background-color: ${Tokens.ColorHover};
  }

  & > img {
    width: 1rem;
    height: 1rem;
  }

  ${({ $inverted }) =>
    $inverted &&
    css`
      background-color: ${Tokens.ColorUIPrimary};

      &:hover,
      &:focus-within {
        background-color: ${Tokens.ColorUIPrimaryDim};
      }

      & > input::placeholder {
        color: ${Tokens.ColorWhite};
      }
    `}
`

export const Input = styled.input`
  all: unset;

  flex: 1;
  height: 100%;

  &::placeholder {
    color: ${Tokens.ColorTextTertiary};
    opacity: 1; /* Firefox */
  }
`

export const Selector = styled.ul<{ $show?: boolean }>`
  all: unset;

  position: absolute;
  top: ${Tokens.HeightInput};
  left: 0;

  border: ${Tokens.ColorWhite} solid 0.3rem;
  border-radius: 1rem;
  width: 100%;
  height: fit-content;
  max-height: 20rem;
  overflow-y: auto;
  padding: 0.5rem;

  display: none;

  font-size: 14px;
  font-weight: 400;
  background-color: ${Tokens.ColorBackground};

  cursor: pointer;

  z-index: 10;
  transition: display ease-in-out 0.25s;

  ${({ $show: show }) =>
    show &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 0.2rem;
    `}
`

export const SelectorOptions = styled.li`
  all: unset;
  color: ${Tokens.ColorTextPrimary};
  text-align: left;
  padding: 0.5rem;
  border-radius: 0.5rem;

  cursor: pointer;

  &:empty {
    height: 1rem;
  }

  &:hover {
    color: ${Tokens.ColorWhite};
    background-color: ${Tokens.ColorUIPrimary};
  }
`

export const IconWrapper = styled.div`
  position: relative;
  gap: 8px;
  display: flex;
  align-items: center;
`
