import { Button, Flex, Form, SelectionOption, Selector } from '@/components'
import { Layout } from '@/components/layout'
import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { Alert, Api } from '@/modules'
import { CompanyUsersRequest, SearchResponse, UserData } from '@shared/models'
import { useState } from 'react'

export function LinkStaff() {
  const { navigate } = useRouting()

  const { sendAlert } = Alert.Hook()

  const [selectedAssessor, setSelectedAssessor] = useState<SelectionOption | undefined>(undefined)
  const [selectedCompany, setSelectedCompany] = useState<SelectionOption | undefined>(undefined)

  const [, , usersResult] = Api.Query<UserData[]>('common/users')
  const [isLoadingCompanies, , companies] = Api.Query<SearchResponse[]>(`staff/companies`)
  const { isRunning: isSavingStaff, run: saveStaff } = Api.Command<boolean>(Api.Methods.POST, `staff`)

  const handleFirstSelect = (option?: SelectionOption) => {
    setSelectedAssessor(option)
    setSelectedCompany(undefined)
  }

  const handleSecondSelect = (option?: SelectionOption) => {
    setSelectedCompany(option)
  }

  function sendErrorMessage(msg?: string): void {
    sendAlert({
      type: 'error',
      title: 'Erro',
      message: msg ?? 'Ocorreu um erro ao enviar a requisição',
    })
  }

  const onSubmit = () => {
    if (!selectedAssessor || !selectedCompany) {
      sendErrorMessage('Selecione um assessor e uma empresa para vincular')
      return
    }

    const input: CompanyUsersRequest = {
      userID: selectedAssessor?.value as string,
      companyID: selectedCompany?.value as string,
    }

    saveStaff<CompanyUsersRequest>({
      input,
      onSuccess: () => {
        navigate(Routes.Assessors)
      },
    })
  }

  return (
    <Layout.Login>
      <Layout.Padder>
        <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
          <Form title="Vincular Usuário" onSubmit={onSubmit} controlParam={isSavingStaff}>
            <Selector.Default
              label="Nome do usuário"
              placeholder="Escolha um usuário"
              options={usersResult?.map((user) => ({ value: user.id, text: user.name }) as SelectionOption) || []}
              onSelect={handleFirstSelect}
              selectedOption={selectedAssessor}
              $fit={true}
              required
            />

            <Selector.Default
              label="Empresa"
              placeholder="Escolha uma empresa"
              options={companies ?? []}
              onSelect={handleSecondSelect}
              selectedOption={selectedCompany}
              $fit={true}
              loading={isLoadingCompanies}
              required
            />

            <Flex $type="row" $justify="space-between" $align="center" $gap={175}>
              <Button.Root onClick={() => navigate(Routes.Assessors)} $variant="secondary">
                Voltar
              </Button.Root>

              <Button.Root type="submit" $variant="primary" $size={200} $loading={isSavingStaff}>
                <Button.Text value="Vincular" />
              </Button.Root>
            </Flex>
          </Form>
        </Flex>
      </Layout.Padder>
    </Layout.Login>
  )
}
