import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import { SelectionBinder, Selector } from '@/components'
import { Env } from '@/configs/env'
import { InputBinder, useInput, useRouting, useSQS } from '@/hooks'
import { Api } from '@/modules'
import { LocalStorageHandler } from '@/utils/LocalStorageHandler'
import {
  MakerResponse,
  Paged,
  Report,
  ReportMonitoringType,
  ReportResponse,
  ReportStatus,
  ReportType,
  SearchResponse,
} from '@shared/models'

const MAX_NUMBER_OF_TEN_MESSAGES = 10
const POLLING_INTERVAL_OF_FIVE_SECONDS = 5000
const REPORT_RESPONSES_KEY = 'REPORT_RESPONSES_KEY'
const localStorageHandler = new LocalStorageHandler<ReportResponse>({
  key: REPORT_RESPONSES_KEY,
  existsFn: (itemA, itemB) => itemA && itemA?.id === itemB?.id,
})

export type DefaultFilters = {
  startDate: string
}

type QueueReportStatus = {
  control_id: string
  status: ReportStatus
}

interface ReportsContextOutput {
  defaultFilters?: DefaultFilters
  applyFilters: () => void
  resetFilters: () => void
  startDateBinder: InputBinder
  typeBinder: SelectionBinder
  monitoringTypeBinder: SelectionBinder
  loadingReports: boolean
  types?: SearchResponse[]
  monitoringTypes?: SearchResponse[]
  reportItems: Report[]
  reports?: Paged<Report>
  updateReportResponse: (response: Report) => void
}

const ReportsContext = createContext<ReportsContextOutput>({} as ReportsContextOutput)

export function ReportsContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const { companyID, queryParams, changeQueryParam } = useRouting()
  const currentPage: number = Number(queryParams.pagina ?? '1')

  const [reportItems, setReportItems] = useState<Report[]>([])
  const [reportResponse, setReportResponse] = useState<Report>()
  const { fetchMessages, deleteMessage, messages } = useSQS<QueueReportStatus>({
    region: process.env[Env.REACT_APP_AWS_REGION] || '',
    accessKeyId: process.env[Env.REACT_APP_AWS_ACCESS_KEY_ID] || '',
    secretAccessKey: process.env[Env.REACT_APP_AWS_SECRET_ACCESS_KEY] || '',
    queueUrl: process.env[Env.REACT_APP_REPORT_PROCESSED_QUEUE] || '',
    maxNumberOfMessages: MAX_NUMBER_OF_TEN_MESSAGES,
  })
  const { binder: startDateBinder, value: startDate, reset: changeStartDate } = useInput()
  const {
    binder: monitoringTypeBinder,
    selectedOption: monitoringType,
    reset: changeMonitoringType,
  } = Selector.Binder()
  const { binder: typeBinder, selectedOption: type, reset: changeType } = Selector.Binder()

  const [types, setTypes] = useState<SearchResponse[]>([])
  const [monitoringTypes, setMonitoringTypes] = useState<SearchResponse[]>([])
  const [, reloadMakerTable, makerData] = Api.Query<MakerResponse[]>(`/companies/${companyID}/makers/list`)
  const [loadReports, loadingReports, reports] = Api.LazyQuery<Paged<Report>>(`/companies/${companyID}/reports`)

  useEffect(() => {
    applyCurrentFilters()
  }, [currentPage])

  function updateReportResponse(response: Report) {
    setReportResponse(response)
  }

  const applyFilters = useCallback(() => {
    const filters: NodeJS.Dict<string | number> = {
      startDate: startDate,
      size: 11,
      page: 0,
    }
    if (type) filters.type = type.value
    if (monitoringType) filters.monitoringType = monitoringType.value
    changeQueryParam({ param: 'pagina', value: '1' })
    loadReports(filters)
  }, [startDate, type, monitoringType, changeQueryParam, loadReports])

  const resetFilters = useCallback(() => {
    changeStartDate(undefined)
    changeType(undefined)
    changeMonitoringType(undefined)
    changeQueryParam({ param: 'pagina', value: '1' })
    loadReports({ startDate: undefined, size: 11, page: 0 })
  }, [changeStartDate, changeType, changeMonitoringType, applyFilters])

  function applyCurrentFilters(): void {
    const filters: NodeJS.Dict<string | number> = {
      startDate: startDate,
      size: 11,
      page: currentPage,
    }
    if (type) filters.type = type.value
    if (monitoringType) filters.monitoringType = monitoringType.value

    loadReports(filters)
  }

  function handleFetchMessages() {
    const reportPending = reportItems?.find((e) => [ReportStatus.STARTED, ReportStatus.UPLOADED].includes(e.status))

    if (reportPending) {
      fetchMessages()
    } else {
      localStorageHandler.clearAll()
    }
  }

  function handleReportItems({
    newItem: newItem,
    removeTempItem: removeFileItem = false,
  }: {
    newItem: Report
    removeTempItem: boolean
  }) {
    if (!newItem?.id) {
      return
    }

    if (!reportItems?.length) {
      setReportItems([newItem])
    }

    const newReportItems = [...reportItems]

    if (removeFileItem) {
      const foundIndex = newReportItems.findIndex((item) => item.id?.includes('TEMP'))
      newReportItems.splice(foundIndex, 1)
    }

    const foundIndex = newReportItems.findIndex((item) => item.id === newItem.id)

    if (foundIndex >= 0) {
      const oldStatus = newReportItems[foundIndex].status
      const newStatus = newItem.status

      if (oldStatus !== newStatus) {
        newReportItems[foundIndex] = newItem
      }
    } else {
      newReportItems.unshift(newItem)
    }
    setReportItems(newReportItems)
  }

  function filterMessagesById(id: string | undefined) {
    if (!id) {
      return
    }

    return messages?.filter((message) => message?.body?.control_id === id)
  }

  function deleteMessagesOfReportResponses() {
    const reportResponses = localStorageHandler.getAll()

    reportResponses?.forEach((item) => {
      if (item?.id) {
        const filtererMessages = filterMessagesById(item?.id)

        if (filtererMessages?.length) {
          filtererMessages.forEach((filtererMessage) => deleteMessage(filtererMessage))
        }
      }
    })
  }

  function syncReportedItemStatus(reportItem: Report, queueReportStatus: QueueReportStatus | undefined): void {
    if (!reportItem || !queueReportStatus) return

    reportItem.status = queueReportStatus.status
  }

  function syncStatusOfReportedItems(items: Report[]) {
    if (!items?.length || !messages?.length) {
      return
    }

    items.forEach((reportedItem) => {
      const filtererMessages = filterMessagesById(reportedItem?.id)
      if (filtererMessages?.length) {
        filtererMessages.forEach((message) => syncReportedItemStatus(reportedItem, message?.body))
      }
    })
  }

  useEffect(() => {
    if (!makerData?.length) {
      return
    }

    const uniqueDataSourceTypes = Array.from(new Set(makerData.map((maker) => maker.dataSourceType))).filter((type) =>
      ['press', 'socialNetwork'].includes(type),
    )

    const types = uniqueDataSourceTypes.map((type) => ({
      text: type === 'press' ? 'Imprensa' : 'Redes Sociais',
      value: type === 'press' ? ReportMonitoringType.PRESS : ReportMonitoringType.NETWORK,
    }))

    const uniqueMonitoringTypes = Array.from(new Set(makerData.map((maker) => maker.monitoringType))).filter((type) =>
      ['monthly', 'daily'].includes(type),
    )

    const monitoringTypes = uniqueMonitoringTypes.map((type) => ({
      text: type === 'monthly' ? 'Mensal' : 'Diário',
      value: type === 'monthly' ? ReportType.MONTHLY : ReportType.DAILY,
    }))

    setTypes(types)
    setMonitoringTypes(monitoringTypes)
  }, [makerData])

  useEffect(() => {
    if (!reportResponse?.id) {
      return
    }

    localStorageHandler.save(reportResponse)

    handleReportItems({
      newItem: reportResponse,
      removeTempItem: true,
    })
    applyFilters()
  }, [reportResponse])

  useEffect(() => {
    if (!messages?.length) {
      return
    }

    const newReportItems = [...reportItems]
    syncStatusOfReportedItems(newReportItems)
    deleteMessagesOfReportResponses()
    setReportItems(newReportItems)
  }, [messages])

  useEffect(() => {
    if (!reportItems?.length) {
      return
    }

    const poolingInterval = setInterval(handleFetchMessages, POLLING_INTERVAL_OF_FIVE_SECONDS)
    return () => clearInterval(poolingInterval)
  }, [reportItems])

  useEffect(() => {
    setReportItems(reports?.elements ? reports.elements : [])
  }, [reports])

  useEffect((): void => {
    reloadMakerTable()
    applyFilters()
  }, [])

  return (
    <ReportsContext.Provider
      value={{
        applyFilters,
        resetFilters,
        startDateBinder,
        typeBinder,
        monitoringTypeBinder,
        types,
        monitoringTypes,
        loadingReports,
        reportItems,
        reports,
        updateReportResponse,
      }}>
      {children}
    </ReportsContext.Provider>
  )
}

export function useReportsContext(): ReportsContextOutput {
  const context = useContext(ReportsContext)
  if (!context) throw new Error('useReportsContext deve ser chamado dentro do contexto correto')
  return context
}
