import { Tokens } from '@/assets/tokens'
import { Button, Divider, Flex, Input, Selector, TableImage } from '@/components'
import { ColumnType, Table } from '@/components/derived'
import { Layout } from '@/components/layout'
import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { Api, Confirmation } from '@/modules'
import { DeleteFive, Search, Star } from '@icon-park/react'
import { CompanyResponse, CompanyUsersResponse, Paged } from '@shared/models'
import { useEffect, useState } from 'react'

export type Image = {
  id: string
  mime: string
  content: string
}

export function Staff() {
  const { navigate } = useRouting()

  const { binder: companyBind, selectedOption: selectedCompany } = Selector.Binder()

  const [, reloadStaff, companyUsers, errorStaff] = Api.Query<Paged<CompanyUsersResponse>>(`/staff/`)
  const [, , companies, errorCompanies] = Api.Query<Paged<CompanyResponse>>('companies?size=10000&orderBy=name:asc')
  const { isRunning: isDeleting, run: deleteStaff } = Api.Command<void>(Api.Methods.DELETE, 'staff/:userID/:companyID')

  if (errorStaff || errorCompanies) throw Error('Unexpected error. Contact the system administrator.')

  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    title: 'Você tem certeza?',
    text: 'Esta ação não tem como ser desfeita. Esse vínculo de assessor-empresa será perdido.',
    confirmText: 'Sim, apagar vínculo',
  })

  const [searchText, setSearchText] = useState('')
  const [filteredStaff, setFilteredStaff] = useState<CompanyUsersResponse[]>([])

  function onConfirmationAccepted(userID: string, companyID: string) {
    deleteStaff<void>({
      binds: [
        ['userID', userID],
        ['companyID', companyID],
      ],
      onSuccess: () => {
        reloadStaff()
      },
    })
  }

  function handleDeleteStaff(userID: string, companyID: string) {
    openConfirmation(() => onConfirmationAccepted(userID, companyID))
  }

  useEffect(() => {
    if (companyUsers?.elements) {
      let filteredData = companyUsers.elements
      if (searchText.trim() !== '') {
        filteredData = filteredData.filter((item) => item.userName.toLowerCase().includes(searchText.toLowerCase()))
      }
      if (selectedCompany) {
        filteredData = filteredData.filter((item) => item.companyID === selectedCompany.value)
      }
      setFilteredStaff(filteredData)
    }
  }, [searchText, selectedCompany, companyUsers])

  const staffColumns: ColumnType<CompanyUsersResponse>[] = [
    {
      key: 'profilePic',
      width: 80,
      render: (_, item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TableImage id={item.systemLogoID} />
        </div>
      ),
    },
    {
      key: 'userName',
      title: 'Usuário',
    },
    {
      key: 'companyName',
      title: 'Empresa',
    },
    {
      key: 'linkedDate',
      title: 'Data Inicial',
      render: (_, staffValue) => {
        const date = new Date(staffValue.linkedDate)
        const day = String(date.getUTCDate()).padStart(2, '0')
        const month = String(date.getUTCMonth() + 1).padStart(2, '0')
        const year = date.getUTCFullYear()
        return `${day}/${month}/${year}`
      },
    },
    {
      key: 'options',
      title: 'Opções',
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => handleDeleteStaff(item.userID, item.companyID)}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <Layout.Root title="Usuários Vinculados">
      <Layout.Padder>
        <Flex $type="column" $justify="flex-start" $align="stretch" $gap={16}>
          <Flex $type="row" $justify="flex-start" $align="flex-start" $gap={16} $fit>
            <Input
              icon={Search}
              placeholder="Pesquisar por usuário..."
              onChange={(value: string) => setSearchText(value)}
            />
            <Selector.Default
              {...companyBind}
              placeholder="Empresa"
              options={companies?.elements.map((item) => ({ text: item.name, value: item.id })) ?? []}>
              <Selector.Icon value={Star} color={Tokens.ColorUIPrimary} />
            </Selector.Default>
            <Button.Root $variant="primary" onClick={() => navigate(Routes.LinkStaff)} $size={300}>
              <Button.Text value="Vincular Usuário" />
            </Button.Root>
          </Flex>
          <Divider.Root>
            <Divider.Title value="Usuários" />
            <Divider.Subtitle value={`${filteredStaff.length} usuários`} />
          </Divider.Root>
          <Table data={filteredStaff} columns={staffColumns} totalPages={companyUsers?.pages} />
        </Flex>
        <Flex $type="row" $justify="space-between" $align="center" $fit>
          <Button.Root $variant="outlined" onClick={() => navigate(Routes.Home)} $size={100}>
            <Button.Text value="Voltar" />
          </Button.Root>
        </Flex>
      </Layout.Padder>
    </Layout.Root>
  )
}
