import { memo, useEffect } from 'react'

import { DeleteFive, Edit } from '@icon-park/react'

import { CompanyClipperResponse, UpdateCompanyClipperRequest } from '@shared/models'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex, Switch } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { ButtonLoadingIcon } from '@/components/core/Button/Button.styles'
import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'

import loadImg from '@/assets/images/load.gif'
import { useClippersContext } from './CompanyClippers.context'

export const CompanyClippersTable = memo(Component)

function Component(): JSX.Element | null {
  const { currentPage, companyID } = useRouting()
  const { isUpdatingCompanyClipper, setCurrentCompanyClipper, updateCompanyClipper } = useClippersContext()
  const { isLoadingCompanyClippers, companyClippers, reloadCompanyClippers } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à essa clipadora serão perdidos',
    confirmText: 'Sim! Apagar clipadora',
  })

  const { isRunning: isDeleting, run: deleteCompanyClipper } = Api.Command<void>(
    Api.Methods.DELETE,
    'companies/:companyID/clippers/:companyClipperID',
  )

  useEffect(() => {
    if (!isUpdatingCompanyClipper) reloadCompanyClippers(currentPage)
  }, [isUpdatingCompanyClipper])

  useEffect(() => {
    reloadCompanyClippers(currentPage)
  }, [currentPage])

  function onSuccess(): void {
    sendAlert({
      type: 'success',
      title: 'Clipadora atualizada',
      message: 'Clipadora atualizada com sucesso',
    })
  }

  function onFail(error: Error): void {
    sendAlert({
      type: 'error',
      title: 'Erro ao atualizar clipadora',
      message: `Ocorreu um erro ao atualizar a clipadora: ${error.message}`,
    })
  }

  function handleUpdateCompanyClipper(companyClipperID: string, input: Partial<UpdateCompanyClipperRequest>) {
    if (!companyClipperID) return
    updateCompanyClipper({
      input,
      binds: [
        ['companyID', companyID!],
        ['companyClipperID', companyClipperID],
      ],
      onSuccess,
      onFail,
    })
  }

  function onConfirmationAccepted(companyClipperID: string) {
    deleteCompanyClipper<void>({
      binds: [
        ['companyID', companyID!],
        ['companyClipperID', companyClipperID],
      ],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão da clipadora',
          message: 'Clipadora excluída com sucesso',
        })
        reloadCompanyClippers(currentPage)
      },
    })
  }

  const columns: ColumnType<CompanyClipperResponse>[] = [
    {
      key: 'clipperName',
      title: 'Clipadora',
      width: 100,
      render: (_, item) => Str.from(item.clipperName).initCap,
    },
    {
      key: 'acronym',
      title: 'Acrônimo',
      width: 160,
    },
    {
      key: 'apiKey',
      title: 'Chave da API',
      render: (_, item) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-all', overflowWrap: 'break-word' }}>
          {item.apiKey ?? '--'}
        </span>
      ),
    },
    {
      key: 'urlFix',
      title: 'URL da API',
      width: 150,
      render: (_, item) => (
        <span style={{ width: '150px', wordWrap: 'break-word', wordBreak: 'break-all', overflowWrap: 'break-word' }}>
          {item.urlFix ?? '--'}
        </span>
      ),
    },
    {
      key: 'monitorAssociatedThemes',
      title: 'Monitorar Tudo',
      width: 30,
      render: (_, item) => (
        <>
          {!isUpdatingCompanyClipper ?
            <Switch.Root
              isChecked={item.monitorAssociatedThemes}
              toggle={() => {
                handleUpdateCompanyClipper(item.id, { monitorAssociatedThemes: !item.monitorAssociatedThemes })
              }}
            />
          : <ButtonLoadingIcon src={loadImg} alt="Carregando" />}
        </>
      ),
    },
    {
      key: 'edit',
      width: 40,
      render: (_, item) => (
        <Button.Root $variant="link" onClick={(): void => setCurrentCompanyClipper(item)}>
          <Button.Icon value={Edit} color={Tokens.ColorTextSecondary} />
        </Button.Root>
      ),
    },
    {
      key: 'options',
      width: 30,
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(item.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Clipadoras registradas" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, companyClippers?.elements.length ?? 0)}/${companyClippers?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table
        isLoading={isLoadingCompanyClippers}
        columns={columns}
        data={companyClippers?.elements}
        totalPages={companyClippers?.pages}
      />
    </>
  )
}
