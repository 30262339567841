/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, useCallback, useContext, useEffect } from 'react'

import {
  BrandResponse,
  ChannelResponse,
  CompanyClipperResponse,
  CompanyResponse,
  Paged,
  PublisherResponse,
  SpokespersonResponse,
  ThemeGroupResponse,
  ThemeResponse,
} from '@shared/models'

import { Api } from '@/modules'

import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { PublisherFilters } from './publishers/Publishers.table'

export type ChannelFilters = {
  name?: string
  page?: number | string
  size?: number
}

interface CompanyDetailsContextOutput {
  isLoadingCompany: boolean
  company?: CompanyResponse
  reloadCompany: () => void

  isLoadingBrands: boolean
  brands?: Paged<BrandResponse>
  reloadBrands: (page: string) => void

  isLoadingThemeGroups: boolean
  themeGroups?: Paged<ThemeGroupResponse>
  reloadThemeGroups: (page: string) => void

  isLoadingThemes: boolean
  themes?: Paged<ThemeResponse>
  reloadThemes: (params: NodeJS.Dict<any>) => void

  isLoadingChannels: boolean
  channels?: Paged<ChannelResponse>
  loadChannels: (filters: ChannelFilters) => void

  isLoadingSpokespeople: boolean
  spokespeople?: Paged<SpokespersonResponse>
  reloadSpokesPeople: (page: string) => void

  isLoadingPublishers: boolean
  publishers?: Paged<PublisherResponse>
  reloadPublishers: (page: PublisherFilters) => void

  isLoadingCompanyClippers: boolean
  companyClippers?: Paged<CompanyClipperResponse>
  reloadCompanyClippers: (page: string) => void

  isFetchingCompanyCompetitors: boolean
  companyCompetitors?: Paged<CompanyResponse>
  fetchCompanyCompetitors: (page: string) => void
}

interface ContextProps {
  children: ReactNode
}

const CompanyDetailsContext = createContext<CompanyDetailsContextOutput>({} as CompanyDetailsContextOutput)

export function CompanyDetailsContextProvider({ children }: ContextProps) {
  const { companyID, navigate } = useRouting()

  const [isLoadingCompany, reloadCompany, company, findCompanyError] = Api.Query<CompanyResponse>(
    `companies/${companyID}`,
  )
  const [loadBrands, isLoadingBrands, brands] = Api.LazyQuery<Paged<BrandResponse>>(`brands?company=${companyID}`)
  const [loadThemeGroups, isLoadingThemeGroups, themeGroups] = Api.LazyQuery<Paged<ThemeGroupResponse>>(
    `theme-groups?company=${companyID}`,
  )
  const [loadThemes, isLoadingThemes, themes] = Api.LazyQuery<Paged<ThemeResponse>>(`themes?company=${companyID}`)
  const [loadChannels, isLoadingChannels, channels] = Api.LazyQuery<Paged<ChannelResponse>>(
    `channels?company=${companyID}`,
  )
  const [loadSpokespersons, isLoadingSpokespeople, spokespersons] = Api.LazyQuery<Paged<SpokespersonResponse>>(
    `spokespersons?company=${companyID}`,
  )
  const [loadPublishers, isLoadingPublishers, publishers] = Api.LazyQuery<Paged<PublisherResponse>>(
    `companies/${companyID}/publishers`,
  )
  const [loadCompanyClippers, isLoadingCompanyClippers, companyClippers] = Api.LazyQuery<Paged<CompanyClipperResponse>>(
    `companies/${companyID}/clippers`,
  )
  const [fetchCompanyCompetitors, isFetchingCompanyCompetitors, companyCompetitors] = Api.LazyQuery<
    Paged<CompanyResponse>
  >(`companies?motherID=${companyID}`)

  const size = 10

  useEffect(() => {
    if (findCompanyError) return navigate(Routes.Home.path)
  }, [findCompanyError])

  const handleReloadBrands = useCallback(
    (page: string) => {
      loadBrands({ size, page })
    },
    [loadBrands],
  )

  const handleReloadThemeGroups = useCallback(
    (page: string) => {
      loadThemeGroups({ size, page })
    },
    [loadThemeGroups],
  )

  const handleReloadThemes = useCallback(
    (params: NodeJS.Dict<any>): void => {
      loadThemes({ ...params, size })
    },
    [loadThemes],
  )

  const handleLoadChannels = useCallback(
    (filters: ChannelFilters): void => {
      if (!filters) {
        filters = {}
      }

      if (!filters.page) {
        filters.page = 1
      }

      if (!filters.size) {
        filters.size = size
      }

      loadChannels(filters)
    },
    [loadChannels],
  )

  const handleReloadSpokesPeople = useCallback(
    (page: string) => {
      loadSpokespersons({ size, page })
    },
    [loadSpokespersons],
  )

  const handleReloadPublishers = useCallback(
    (filters: PublisherFilters): void => {
      if (!filters) {
        filters = {}
      }

      if (!filters.page) {
        filters.page = 1
      }

      if (!filters.size) {
        filters.size = size
      }

      loadPublishers(filters)
    },
    [loadPublishers],
  )

  const handleReloadCompanyClippers = useCallback(
    (page: string): void => {
      loadCompanyClippers({ size, page })
    },
    [loadCompanyClippers],
  )

  const handleFetchCompanyCompetitors = useCallback(
    (page: string): void => {
      fetchCompanyCompetitors({ size, page })
    },
    [fetchCompanyCompetitors],
  )

  return (
    <CompanyDetailsContext.Provider
      value={{
        isLoadingCompany,
        company,
        reloadCompany,

        isLoadingBrands,
        brands,
        reloadBrands: handleReloadBrands,

        isLoadingThemeGroups,
        themeGroups,
        reloadThemeGroups: handleReloadThemeGroups,

        isLoadingThemes,
        themes,
        reloadThemes: handleReloadThemes,

        isLoadingChannels,
        channels,
        loadChannels: handleLoadChannels,

        isLoadingSpokespeople,
        spokespeople: spokespersons,
        reloadSpokesPeople: handleReloadSpokesPeople,

        isLoadingPublishers,
        publishers,
        reloadPublishers: handleReloadPublishers,

        isLoadingCompanyClippers,
        companyClippers,
        reloadCompanyClippers: handleReloadCompanyClippers,

        isFetchingCompanyCompetitors,
        companyCompetitors,
        fetchCompanyCompetitors: handleFetchCompanyCompetitors,
      }}>
      {children}
    </CompanyDetailsContext.Provider>
  )
}

export function useCompanyContext(): CompanyDetailsContextOutput {
  const context = useContext(CompanyDetailsContext)
  if (!context) {
    throw new Error('useCompanyContext deve ser chamado dentro do CompanyDetailsContextProvider!')
  }
  return context
}
