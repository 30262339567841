import { Close, DeleteFive, SmilingFace, Star, Tag } from '@icon-park/react'

import { PolarityID, PolarityValue, UpdateMentionRequest } from '@shared/models'

import { Tokens } from '@/assets/tokens'

import { useRouting } from '@/hooks'
import { Alert, Api, Confirmation } from '@/modules'
import { CommandMethods } from '@/modules/api/useCommand'

import { Str, truncateString } from '@/utils/strings'

import { Button, Flex, Selector, TableImage } from '@/components'

import { useMentionsContext } from '../../Mentions.context'
import { appendAtIfNeeded } from '../../utils'

import { useObserver } from '@/modules/observer'
import { MentionResponse } from '@shared/models'
import { Container, Overlay } from './EditModal.styles'

export function EditModal(): JSX.Element | null {
  const { companyID } = useRouting()

  const {
    currentMention: currMention,
    polarities,
    isSearchingRoles,
    roles,
    searchRoles,
    isSearchingThemes,
    themes,
    selectMention,
    applyFilters,
  } = useMentionsContext()

  const { sendMessage } = useObserver()

  const { binder: bindPolarity, selectedOption: polarity, reset: resetPolarity } = Selector.Binder()
  const { binder: bindRole, selectedOption: role, reset: resetRole } = Selector.Binder()
  const { binder: bindTheme, selectedOption: theme, reset: resetTheme } = Selector.Binder()

  const { sendAlert } = Alert.Hook()

  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à essa menção serão perdidos',
    confirmText: 'Sim! Apagar menção',
  })

  const { isRunning: isUpdatingMention, run: updateMention } = Api.Command<UpdateMentionRequest>(
    CommandMethods.PATCH,
    `companies/:companyID/mentions/:mentionID`,
  )
  const { isRunning: isDeletingMention, run: deleteMention } = Api.Command<void>(
    CommandMethods.DELETE,
    `companies/:companyID/mentions/:mentionID`,
  )

  function onConfirmationAccepted(mentionID: string): void {
    deleteMention<void>({
      binds: [
        ['companyID', companyID!],
        ['mentionID', mentionID],
      ],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão da menção',
          message: 'Menção excluída com sucesso',
        })
        selectMention()
        applyFilters()
      },
    })
  }

  const currentMention = currMention?.val

  function onSuccess(): void {
    resetPolarity()
    resetRole()
    resetTheme()
    if (currentMention) {
      const newMention: MentionResponse = {
        ...currentMention,
        polarity: (polarity?.text || currentMention.polarity).toString().toUpperCase(),
        theme: (theme?.text || currentMention.theme).toString().toUpperCase(),
        role: (role?.text || currentMention.role).toString().toUpperCase(),
      }
      sendMessage('mention_updated', { idx: currMention.idx, mention: newMention })
    }

    selectMention(undefined)
  }

  function onFail(error: Error): void {
    sendAlert({
      type: 'error',
      title: 'Erro ao atualizar menção',
      message: `Ocorreu um erro ao atualizar a menção: ${error.message}`,
    })
  }

  function handleUpdate(): void {
    if (!polarity && !role && !theme) return
    const input = {
      brandInternalID: currentMention!.brandInternalID,
    } as UpdateMentionRequest

    if (polarity) input.polarityInternalID = polarity.value as number
    if (role) input.roleInternalID = role.value as number
    if (theme) {
      input.themeInternalID = theme.value as number
      input.oldThemeInternalID = currentMention!.themeInternalID
    }

    updateMention({
      input,
      binds: [
        ['companyID', companyID!],
        ['mentionID', currentMention!.postID],
      ],
      onSuccess,
      onFail,
    })
  }

  if (!companyID || !currentMention) return null

  return (
    <Overlay>
      <Container>
        <Flex $type="row" $justify="space-between" $align="center" $fit>
          <h3>Atualização da Anotação</h3>
          <Button.Root $variant="link" onClick={() => selectMention(undefined)}>
            <Button.Icon value={Close} color={Tokens.ColorTextPrimary} />
          </Button.Root>
        </Flex>
        <Flex $type="row" $justify="flex-start" $align="flex-start" $fit $gap={30}>
          <Flex $type="column" $justify="center" $align="flex-start" $gap={10}>
            <TableImage id={currentMention.channelLogoID} hyperlink={currentMention.hyperlink} />
            <TableImage id={currentMention.brandLogo} />
          </Flex>
          <Flex $type="column" $justify="center" $align="stretch" $fit>
            <h4>{appendAtIfNeeded(currentMention.publisher.toLowerCase())}</h4>
            <span>{truncateString(currentMention.publication, 800)}</span>
          </Flex>
        </Flex>
        <Flex $type="row" $justify="space-between" $align="center" $fit $gap={16}>
          <Selector.Default
            {...bindPolarity}
            placeholder="Polaridade"
            options={
              polarities?.map((item) => ({
                text: Str.from(item.text).initCap,
                value: PolarityID.get(item.value as PolarityValue) ?? 0,
              })) ?? []
            }>
            <Selector.Icon value={SmilingFace} color={Tokens.ColorUIPrimary} />
          </Selector.Default>
          <Selector.Searchable
            {...bindRole}
            placeholder="Papel"
            options={roles?.map((item) => ({ text: Str.from(item.text).initCap, value: item.value })) ?? []}
            onSearch={(searchParam: string): void => {
              searchRoles({ name: searchParam })
            }}
            loading={isSearchingRoles}>
            <Selector.Icon value={Star} color={Tokens.ColorUIPrimary} />
          </Selector.Searchable>
          <Selector.Default
            {...bindTheme}
            placeholder="Tema"
            options={
              themes?.map((item) => ({
                text: Str.from(item.text).initCap,
                value: item.value,
              })) ?? []
            }
            loading={isSearchingThemes}>
            <Selector.Icon value={Tag} color={Tokens.ColorUIPrimary} />
          </Selector.Default>
        </Flex>
        <Flex $type="row" $justify="space-between" $align="center" $fit>
          <Button.Root
            onClick={() => openConfirmation(() => onConfirmationAccepted(currentMention.postID))}
            $variant="danger"
            $loading={isDeletingMention}>
            <Button.Icon value={DeleteFive} color={Tokens.ColorWhite} />
            <Button.Text value="Excluir" />
          </Button.Root>
          <Flex $type="row" $justify="flex-end" $align="center" $fit $gap={16}>
            <Button.Root
              $variant="secondary"
              onClick={() => selectMention(undefined)}
              style={{
                width: '7rem',
              }}>
              <Button.Text value="Cancelar" />
            </Button.Root>
            <Button.Root
              $variant="primary"
              onClick={handleUpdate}
              $loading={isUpdatingMention}
              style={{
                width: '7rem',
              }}>
              <Button.Text value="Atualizar" />
            </Button.Root>
          </Flex>
        </Flex>
      </Container>
    </Overlay>
  )
}
