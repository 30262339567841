import {
  Button,
  Flex,
  Form,
  HorizontalSelectedList,
  Input,
  SelectionOption,
  Selector,
  Uploader,
  UploaderOutput,
} from '@/components'
import { useSelectedList } from '@/components/core/HorizontalSelectedList/HorizontalSelectedList.hooks'
import { SkeletonLoader } from '@/components/derived'
import { ProfilePic } from '@/components/derived/UserInfo/UserInfo.styles'
import { Layout } from '@/components/layout'
import { Env, Routes } from '@/configs'
import { useFileUploader, useRouting } from '@/hooks'
import { Alert, Api } from '@/modules'
import { Earth } from '@icon-park/react'
import { UserData, UserDetailResponse, UserResponseRole } from '@shared/models'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export function CreateUser() {
  const { sendAlert } = Alert.Hook()
  const { navigate } = useRouting()
  const { userID } = useParams<{ userID: string }>()

  const isEditing = userID && userID !== 'undefined'

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const {
    binder: regionsBinder,
    selectedOptions: selectedRegions,
    setSelectedOptions: setSelectedRegions,
  } = useSelectedList(() => {})
  const [role, setRole] = useState('')
  const {
    value: profilePic,
    bind: profilePicBinder,
    reset: resetProfilePic,
    setValue: setFileUploader,
  } = useFileUploader()

  const roleOptions: SelectionOption[] = Object.keys(UserResponseRole).map((key) => {
    const value = UserResponseRole[key as keyof typeof UserResponseRole]
    const text = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    return { value, text }
  })
  const regionOptions: SelectionOption[] = [
    { value: 'RJ', text: 'RJ' },
    { value: 'SP', text: 'SP' },
    { value: 'DF', text: 'DF' },
  ]
  const [loadUser, isLoadingUser, user] = Api.LazyQuery<UserDetailResponse>(`users/${userID}`)
  const { isRunning: isCreatingUser, run: sendUserRequest } = Api.Command<UserData>(
    isEditing ? Api.Methods.PUT : Api.Methods.POST,
    isEditing ? `users/${userID}` : 'users/',
  )

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!email) return false
    if (!emailRegex.test(email)) {
      sendErrorMessage('E-mail inválido')
      return false
    }
    return true
  }

  function sendErrorMessage(msg?: string): void {
    sendAlert({
      type: 'error',
      title: 'Erro',
      message: msg ?? 'Ocorreu um erro ao enviar a requisição',
    })
  }

  function cleanFields(): void {
    setEmail('')
    setName('')
    setRole('')
    resetProfilePic()
    setSelectedRegions([])
  }

  const onSubmit = () => {
    if (!validateEmail(email)) return

    sendUserRequest({
      input: {
        name,
        email,
        role,
        regions: selectedRegions.map((region) => region.value),
        profilePic: profilePic,
      },
      onSuccess: (user: UserData) => {
        const action = isEditing ? 'atualizado' : 'cadastrado'
        cleanFields()
        sendAlert({
          type: 'success',
          title: 'Sucesso',
          message: `Usuário ${user.name} ${action} com sucesso!`,
        })
        navigate(Routes.Users)
      },
    })
  }

  useEffect(() => {
    if (isEditing) {
      loadUser()
    }
  }, [userID])

  useEffect(() => {
    if (!user) return
    setEmail(user.email)
    setName(user.name)
    setRole(user.role)
    const selectedOptions: SelectionOption[] =
      user.regions?.map((region) => {
        return { value: region, text: region }
      }) ?? []
    setSelectedRegions(selectedOptions)
    const profilePicture: UploaderOutput = {
      name: user.name,
      mime: user.profilePic.split('.')[user.profilePic.length - 1] ?? 'jpeg',
      content: user.profilePic,
    }
    if (user.profilePic) {
      setFileUploader(profilePicture)
    }
  }, [user])

  return (
    <Layout.Login>
      <Layout.Padder>
        {isEditing && isLoadingUser ?
          <SkeletonLoader amount={1} />
        : <Flex $type="row" $justify="center" $align="center" $gap={8}>
            <Form title={isEditing ? 'Editar Usuário' : 'Cadastrar Novo Usuário'} onSubmit={onSubmit}>
              <Input
                label="E-mail"
                value={email}
                onChange={(value) => setEmail(value)}
                placeholder="Digite o e-mail"
                required
              />
              <Input
                label="Nome"
                value={name}
                onChange={(value) => setName(value)}
                placeholder="Digite o nome completo"
                required
              />
              <Selector.Default
                label="Role"
                placeholder="Escolha o papel do usuário"
                options={roleOptions}
                onSelect={(option) => setRole(option?.value as UserResponseRole)}
                selectedOption={roleOptions.find((option) => option.value === role)}
                $fit={true}
                required
              />
              <HorizontalSelectedList
                icon={Earth}
                label="Regiões"
                isLoading={false}
                placeholder="Selecione as regiões"
                options={regionOptions}
                preSelectedOptions={selectedRegions}
                {...regionsBinder}
              />
              <Flex $type="row" $justify="center" $align="center" style={{ maxWidth: '575px' }}>
                {profilePic ?
                  <ProfilePic
                    alt={profilePic?.name}
                    src={profilePic?.content}
                    style={{
                      maxWidth: '100px',
                      maxHeight: '100px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  />
                : <img
                    src={process.env[Env.REACT_APP_DEFAULT_PICTURE]}
                    alt="Foto de perfil padrão"
                    style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                  />
                }

                <Flex
                  $type="column"
                  $align="stretch"
                  $gap={24}
                  $fit
                  style={{
                    maxWidth: '100%',
                    paddingLeft: '8px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  <Uploader
                    label="Foto de Perfil"
                    {...profilePicBinder}
                    requiredExtensions={['jpg', 'jpeg', 'png', 'svg']}
                    required
                  />
                </Flex>
              </Flex>

              <Flex $type="row" $justify="space-between" $align="center" $gap={175}>
                <Button.Root
                  onClick={() => {
                    cleanFields()
                    navigate(Routes.Users)
                  }}
                  $size={200}
                  $variant="secondary">
                  Voltar
                </Button.Root>

                <Button.Root type="submit" $variant="primary" $size={200} $loading={isCreatingUser}>
                  <Button.Text value={isEditing ? 'Salvar' : 'Cadastrar'} />
                </Button.Root>
              </Flex>
            </Form>
          </Flex>
        }
      </Layout.Padder>
    </Layout.Login>
  )
}
