import React from 'react'

import { generateRandomID } from '@/utils/idGenerator'

import { Component, ComponentContainer, Input, Label } from './Switch.styles'

type Props = {
  label?: string
  isChecked: boolean
  toggle: () => void
}

export const SwitchComponent: React.FC<Props> = ({ label, isChecked, toggle }) => {
  const id = generateRandomID()

  return (
    <>
      <ComponentContainer $hasLabel={!!label} htmlFor={id}>
        {label && <Label>{label}</Label>}
        <Component checked={isChecked} />
      </ComponentContainer>
      <Input id={id} type="checkbox" onChange={toggle} checked={isChecked} />
    </>
  )
}
