/* eslint-disable react-refresh/only-export-components */
import React, { ChangeEvent, ElementType, ReactNode, RefObject, memo, useEffect, useState } from 'react'

import { Down, Up } from '@icon-park/react'

import loadingImgSrc from '@/assets/images/load.gif'
import { Tokens } from '@/assets/tokens'

import { ComboBoxItem } from '@/hooks'

import { Icon } from '../Icon/Icon.component'
import {
  ComboBoxIconContainer,
  ComboBoxIconContainerProps,
  ComponentContainer,
  ComponentWrapper,
  Input,
  Label,
  SelectedItem,
  Selector,
  SelectorOptions,
  StyledProps,
} from './ComboBox.styles'
import { CleanButton } from '../Selector/Selection.subcomponents'

export const ComboBox = {
  Root: memo(RootComponent),
  Icon: memo(IconComponent),
}

interface RootProps extends StyledProps {
  label?: string
  title?: string
  placeholder?: string
  loading?: boolean
  options: ComboBoxItem[]
  value?: ComboBoxItem
  onSelect: (value?: ComboBoxItem, title?: string) => void
  onSearch?: (search: string) => void
  children?: ReactNode
  required?: boolean
  className?: string
}

function RootComponent({
  label,
  title,
  placeholder,
  loading = false,
  options,
  value,
  onSelect,
  onSearch,
  children,
  required = false,
  ...rest
}: RootProps) {
  const ref: RefObject<HTMLDivElement> = React.createRef()

  const [selectedItem] = useState<string | undefined>(placeholder)
  const [show, setShow] = useState<boolean>(false)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => onSearch!(target.value)

  const handleSelection = (item?: ComboBoxItem) => {
    if (!item) {
      onSelect(undefined, title)
    } else {
      onSelect(item)
    }
    setTimeout(() => setShow(false), 100)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <ComponentContainer ref={ref}>
      {label && (
        <Label>
          {label}
          {required && '*'}
        </Label>
      )}
      <ComponentWrapper onClick={() => setShow((value) => !value)} title={title} {...rest}>
        {children}
        {onSearch ?
          <Input placeholder={value?.text ?? placeholder} onChange={handleChange} />
        : <SelectedItem>{selectedItem}</SelectedItem>}
        {selectedItem !== title && <CleanButton onClick={(): void => handleSelection()} />}
        {loading ?
          <img alt="loading" src={loadingImgSrc} />
        : !onSearch && <Icon value={show ? Up : Down} size={16} color={Tokens.ColorUIPrimary} />}
        {options.length > 0 && (
          <Selector $show={show} onBlur={() => setShow(false)} tabIndex={0}>
            {options.map((option) => (
              <SelectorOptions key={option.value} onClick={() => handleSelection(option)}>
                {option.text}
              </SelectorOptions>
            ))}
          </Selector>
        )}
      </ComponentWrapper>
    </ComponentContainer>
  )
}

interface IconProps extends ComboBoxIconContainerProps {
  value: ElementType
}

function IconComponent({ color, value: Icon }: IconProps) {
  return (
    <ComboBoxIconContainer color={color}>
      <Icon size={16} />
    </ComboBoxIconContainer>
  )
}
