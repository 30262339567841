export enum Tokens {
  ColorWhite = '#FFFFFF',
  ColorBackground = '#F3F4F8',
  ColorBackgroundSecondary = '#F4F4F5',
  ColorHover = '#EEF0F5',
  ColorUIPrimary = '#01C38D',
  ColorUIPrimaryDim = '#01A779',
  ColorUIWarn = '#FFC765',
  ColorUIWarnDark = '#FF7E26',
  ColorUIWarnDim = '#FAA111',
  ColorUIDanger = '#FF4C64',
  ColorUIDangerDim = '#CE4A64',

  ColorTextPrimary = '#13141C',
  ColorTextSecondary = '#5E6287',
  ColorTextSecondaryForeground = '#18181B',
  ColorTextTertiary = '#94A2BC',
  ColorLight = '#CBD1E6',
  ColorBorder = '#DEE2EF',

  RootWithPaddingWidth = '64.75rem',
  DefaultInputWidth = '20rem',
  HeightInput = '3.25rem',
  RadiusInput = '10px',
  HeaderHeight = '78px',
}
