import { useState } from 'react'
import { SelectionOption } from '../Selector'

export type SearchFunc = (searchParam: string) => void

export interface Binder {
  onSearch: SearchFunc
  onChange: (changed: SelectionOption[]) => void
}

export type BindOutput = {
  binder: Binder
  selectedOptions: SelectionOption[]
  setSelectedOptions: (options: SelectionOption[]) => void
}

export function useSelectedList(onSearch: SearchFunc): BindOutput {
  const [selectedOptions, setSelectedOptions] = useState<SelectionOption[]>([])

  function onChange(changes: SelectionOption[]): void {
    setSelectedOptions(changes)
  }

  const binder: Binder = { onSearch, onChange }

  return {
    binder,
    selectedOptions,
    setSelectedOptions,
  }
}
