import { useState } from 'react'

import { UploaderOutput } from '@/components'

export interface FileUploaderBind {
  value?: UploaderOutput
  onUpload: (input: UploaderOutput) => void
}

interface Output {
  value?: UploaderOutput
  reset: () => void
  setValue: (input: UploaderOutput | undefined) => void
  bind: FileUploaderBind
}

export function useFileUploader(): Output {
  const [value, set] = useState<UploaderOutput | undefined>(undefined)

  return {
    value,
    reset: (): void => set(undefined),
    setValue: (input: UploaderOutput | undefined): void => set(input),
    bind: {
      value,
      onUpload: (input: UploaderOutput): void => set(input),
    },
  }
}
