import { DeleteFive } from '@icon-park/react'
import { memo, useEffect } from 'react'

import { SearchResponse, ThemeResponse, UpdateThemeRequest } from '@shared/models'

import { useRouting } from '@/hooks'
import { Alert, Api, Confirmation } from '@/modules'

import { Divider, Flex, SelectedList, Switch, TableImage } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { Item, useSelectedList } from '@/components/core/SelectedList/SelectedList.hooks'
import { getFromToElements } from '@/utils/pagination'
import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'
import { ThemeTableContainer } from './Themes.styles'

export const ThemesTable = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, currentPage } = useRouting()
  const { isLoadingThemes, themes, reloadThemes } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    title: 'Você tem certeza?',
    text: 'Deseja desativar o tema?',
    confirmText: 'Sim! Desativar tema.',
  })

  const { binder: brandsFilterBinder, selectedOptions: selectedBrandsForFilter } = useSelectedList(
    (param: string): void => {
      loadBrands({ name: param })
    },
  )
  const { binder: themeGroupsFilterBinder, selectedOptions: selectedThemeGroupsForFilter } = useSelectedList(
    (param: string): void => {
      loadThemeGroups({ name: param })
    },
  )

  const [isLoadingBrands, loadBrands, brands] = Api.Query<SearchResponse[]>(`brands/search?company=${companyID}`)
  const [isLoadingThemeGroups, loadThemeGroups, themeGroups] = Api.Query<SearchResponse[]>(
    `theme-groups/search?company=${companyID}`,
  )
  const { isRunning: isUpdatingTheme, run: updateTheme } = Api.Command<UpdateThemeRequest>(
    Api.Methods.PATCH,
    'themes/:id',
  )

  const selectedBrandsParsed = selectedBrandsForFilter.map((item: Item) => item.value).join(',')
  const selectedThemeGroupsParsed = selectedThemeGroupsForFilter.map((item: Item) => item.value).join(',')

  useEffect(() => {
    reloadThemes({
      page: currentPage,
      brands: selectedBrandsParsed,
      themeGroups: selectedThemeGroupsParsed,
    })
  }, [isUpdatingTheme])

  useEffect(() => {
    reloadThemes({
      page: currentPage,
      brands: selectedBrandsParsed,
      themeGroups: selectedThemeGroupsParsed,
    })
  }, [currentPage, selectedBrandsParsed, selectedThemeGroupsParsed])

  function onConfirmationAccepted(id: string, isActive: boolean): void {
    if (isActive === undefined) return
    const input = {
      isActive,
      companyID,
    } as UpdateThemeRequest

    updateTheme({
      input,
      binds: [['id', id!]],
      onSuccess,
      onFail,
    })
  }

  function onSuccess(): void {
    sendAlert({
      type: 'success',
      title: 'Tema atualizado',
      message: 'Tema atualizado com sucesso',
    })
  }

  function onFail(error: Error): void {
    sendAlert({
      type: 'error',
      title: 'Erro ao atualizar tema',
      message: `Ocorreu um erro ao atualizar a tema: ${error.message}`,
    })
  }

  const columns: ColumnType<ThemeResponse>[] = [
    {
      key: 'brandName',
      render: (_, theme) => <TableImage id={theme.brandLogo} />,
    },
    {
      key: 'value',
      title: 'Nome',
    },
    {
      key: 'options',
      title: 'Ativo',
      render: (_, theme) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Switch.Root
            isChecked={theme.isActive ?? false}
            toggle={() => {
              theme.isActive ?
                openConfirmation(() => onConfirmationAccepted(theme.id, !theme.isActive))
              : onConfirmationAccepted(theme.id, !theme.isActive)
            }}
          />
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Temas registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, themes?.elements.length ?? 0)}/${themes?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>

      <ThemeTableContainer>
        <SelectedList
          placeholder="Marcas"
          isLoading={isLoadingBrands}
          options={
            brands?.map((item: SearchResponse): Item => ({ text: Str.from(item.text).initCap, value: item.value })) ??
            []
          }
          {...brandsFilterBinder}
        />
        <SelectedList
          placeholder="Grupos"
          isLoading={isLoadingThemeGroups}
          options={
            themeGroups?.map(
              (item: SearchResponse): Item => ({ text: Str.from(item.text).initCap, value: item.value }),
            ) ?? []
          }
          {...themeGroupsFilterBinder}
        />
        <Table isLoading={isLoadingThemes} columns={columns} data={themes?.elements} totalPages={themes?.pages} />
      </ThemeTableContainer>
    </>
  )
}
