import { InputHTMLAttributes } from 'react'
import { css, styled } from 'styled-components'

import { Tokens } from '@/assets/tokens'

import { variants } from './Button.variants'

export interface RootContainerProps extends InputHTMLAttributes<HTMLButtonElement> {
  $size?: number
  $variant?: 'primary' | 'secondary' | 'danger' | 'outlined' | 'link' | 'warn' | 'primaryOrange'
  $noHover?: boolean
  $loading?: boolean
}

export const RootContainer = styled.button<RootContainerProps>`
  border: unset;

  min-width: ${Tokens.HeightInput};
  height: ${Tokens.HeightInput};
  border-radius: ${Tokens.RadiusInput};
  padding: unset;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;

  font-size: 14px;
  font-weight: 400;

  color: ${Tokens.ColorTextPrimary};
  background-color: ${Tokens.ColorBackground};

  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  cursor: pointer;

  &:not(:has(div:only-child)) {
    padding: 0 22px;
  }

  &:hover {
    background-color: ${Tokens.ColorBorder};
  }

  ${({ $variant: variant }) => variant && variants[variant]}

  ${({ $loading: loading = false }) =>
    loading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
    `}

  ${({ $noHover }) =>
    $noHover &&
    css`
      background-color: unset;
      &:hover {
        background-color: unset;
      }
    `}

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
`

export interface ButtonChildProps {
  color?: string
}

export const ButtonIconContainer = styled.div<ButtonChildProps>`
  & > span {
    padding: unset;
    margin: unset;
  }

  ${({ color }) =>
    color &&
    css`
      & > span {
        color: ${color};
      }
    `}
`

export const ButtonSpan = styled.span<ButtonChildProps>`
  color: ${Tokens.ColorTextPrimary};
`

export const ButtonLoadingIcon = styled.img`
  width: 1.2rem;
  height: 1.2rem;
`
