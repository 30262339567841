export enum Env {
  REACT_APP_ENV = 'REACT_APP_ENV',
  REACT_APP_API_URL = 'REACT_APP_API_URL',
  REACT_APP_DASH_API_HOST = 'REACT_APP_DASH_API_HOST',
  REACT_APP_AWS_REGION = 'REACT_APP_AWS_REGION',
  REACT_APP_AWS_ACCESS_KEY_ID = 'REACT_APP_AWS_ACCESS_KEY_ID',
  REACT_APP_AWS_SECRET_ACCESS_KEY = 'REACT_APP_AWS_SECRET_ACCESS_KEY',
  REACT_APP_AWS_COGNITO_CLIENT_ID = 'REACT_APP_AWS_COGNITO_CLIENT_ID',
  REACT_APP_SPREADSHEET_UPDATED_QUEUE = 'REACT_APP_SPREADSHEET_UPDATED_QUEUE',
  REACT_APP_REPORT_PROCESSED_QUEUE = 'REACT_APP_REPORT_PROCESSED_QUEUE',
  REACT_APP_DEFAULT_PICTURE = 'REACT_APP_DEFAULT_PICTURE',
}

export const Envs: string[] = Object.values(Env)
  .filter((value: Env) => typeof value === 'string')
  .map((value: Env) => value as string)
