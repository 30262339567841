import { ChangeEvent, ElementType, FormEvent, RefObject } from 'react'

import { generateRandomID } from '@/utils/idGenerator'

import { InnerContainer, InputContainer, InputIn, Label } from './Input.styles'
import { Tokens } from '@/assets/tokens'

interface Props {
  id?: string
  value?: string
  type?: string
  min?: number
  max?: number
  innerRef?: RefObject<HTMLInputElement>
  preMask?: string
  label?: string
  icon?: ElementType
  placeholder?: string
  required?: boolean
  minLength?: number
  maxLength?: number
  date?: boolean
  disabled?: boolean
  readOnly?: boolean
  innerBackground?: string
  onChange: (value: string) => void
  onKeyDown?: (key: string) => void
}

export function Input({
  id,
  value,
  innerRef,
  preMask,
  label,
  icon: Icon,
  placeholder,
  required,
  minLength,
  maxLength,
  date,
  disabled,
  readOnly,
  innerBackground = Tokens.ColorBackground,
  onChange,
  onKeyDown,
  ...rest
}: Props) {
  const inputID = id ? id : generateRandomID()

  function onInput(input: FormEvent<HTMLInputElement>) {
    if (maxLength && input.currentTarget.value.length > maxLength) {
      input.currentTarget.value = input.currentTarget.value.slice(0, maxLength)
    }
    if (date) {
      const inputValue = input.currentTarget.value
      // Remove caracteres que não são dígitos ou barras
      const cleanedValue = inputValue.replace(/[^\d/]/g, '')
      // Limita o tamanho da string
      const formattedValue = cleanedValue.slice(0, 10)
      // Aplica a máscara
      input.currentTarget.value = formattedValue
        .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
        .replace(/^(\d{2})\/(\d{2})\/(\d{4})\d+$/, '$1/$2/$3')
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    if (!onChange) return
    const value = event.target.value
    onChange(value)
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (!onKeyDown) return
    const key = event?.key
    onKeyDown(key)
  }

  return (
    <InputContainer>
      {label && <Label htmlFor={inputID}>{`${label}${required ? '*' : ''}`}</Label>}
      <InnerContainer style={{ backgroundColor: innerBackground }}>
        {Icon && <Icon />}
        {preMask && <span>{preMask}</span>}
        <InputIn
          ref={innerRef}
          id={inputID}
          value={value}
          placeholder={placeholder}
          required={required}
          minLength={minLength}
          onInput={onInput}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </InnerContainer>
    </InputContainer>
  )
}
