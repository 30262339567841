import { ElementType, useEffect, useState } from 'react'

import { Close } from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { Icon } from '../Icon/Icon.component'
import { SelectionOption, Selector } from '../Selector'
import { SearchFunc } from './HorizontalSelectedList.hooks'
import {
  Container,
  InputContainer,
  ItemButton,
  ListContainer,
  ListItem,
  ListItemText,
} from './HorizontalSelectedList.styles'

type Props = {
  label?: string
  icon?: ElementType
  placeholder: string
  isLoading: boolean
  options: SelectionOption[]
  preSelectedOptions?: SelectionOption[]
  onSearch: SearchFunc
  onChange: (changes: SelectionOption[]) => void
}

export function HorizontalSelectedList({
  label,
  icon,
  placeholder,
  isLoading,
  options,
  preSelectedOptions,
  onSearch,
  onChange,
}: Props): JSX.Element | null {
  const [selectedOptions, setSelectedOptions] = useState<SelectionOption[]>([])

  useEffect(() => {
    if (!preSelectedOptions) return
    setSelectedOptions(preSelectedOptions)
    onChange(preSelectedOptions)
  }, [preSelectedOptions])

  function handleSelection(item?: SelectionOption): void {
    if (item) {
      const changes = [...selectedOptions, item]
      setSelectedOptions(changes)
      onChange(changes)
    }
  }

  function handleClickFromSelectedList(index: number): void {
    const changes = selectedOptions.filter((_, idx) => idx !== index)
    setSelectedOptions(changes)
    onChange(changes)
  }

  const availableOptions = options.filter((option: SelectionOption): boolean => {
    return !selectedOptions.map((selectedOption: SelectionOption) => selectedOption.value).includes(option.value)
  })

  return (
    <Container>
      <InputContainer>
        <Selector.Searchable
          label={label}
          placeholder={placeholder}
          options={availableOptions}
          onSearch={onSearch}
          onSelect={handleSelection}
          loading={isLoading}
          required>
          {icon && <Selector.Icon value={icon} />}
        </Selector.Searchable>
      </InputContainer>
      <ListContainer>
        {selectedOptions.map((item: SelectionOption, index: number) => (
          <ListItem
            key={item.value}
            onClick={() => {
              handleClickFromSelectedList(index)
            }}>
            <ListItemText>{item.text}</ListItemText>
            <ItemButton>
              <Icon value={Close} size={6} color={Tokens.ColorWhite} />
            </ItemButton>
          </ListItem>
        ))}
      </ListContainer>
    </Container>
  )
}
