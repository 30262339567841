import styled from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Component = styled.label`
  all: unset;
  width: 100%;
  height: 100%;
  border: dashed 2px ${Tokens.ColorTextTertiary};
  border-radius: calc(${Tokens.RadiusInput} * 2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  cursor: default;
`

export const Input = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  display: none;
`
