import { FileExcel, UploadOne } from '@icon-park/react'

import { Flex, Tooltip } from '@/components'

import { Tokens } from '@/assets/tokens'
import { useRouting } from '@/hooks'
import { Alert, Api } from '@/modules'
import { UploadStatus } from '@shared/models'
import { Component, DownloadButton, FileName, ProgressBar } from './Card.styles'

export interface UploadItem {
  id?: string
  originalFileName: string
  percentage: number
  status: UploadStatus
  metadata?: string
}

interface CardProps {
  item: UploadItem
}

export function Card({ item }: CardProps) {
  const { companyID } = useRouting()
  const { sendAlert } = Alert.Hook()

  const [getSpreadsheet] = Api.PromiseQuery<{ url: string }>(`companies/${companyID}/spreadsheets/${item.id}/logs`)

  const handleDownload = async () => {
    try {
      const [response] = await getSpreadsheet()
      if (!response?.url) return

      sendAlert({
        type: 'success',
        title: 'Sucesso',
        message: 'Dados exportados com sucesso!',
      })
      window.location.href = response.url
    } catch (error) {
      sendAlert({
        type: 'error',
        title: 'Erro',
        message: 'Erro ao exportar dados.',
      })
    }
  }

  return (
    <Component>
      <div title={item.originalFileName}>
        <Flex $type="row" $justify="flex-start" $align="flex-start" $gap={12} style={{ width: '100%' }}>
          <UploadOne size={20} />
          <FileName>{item.originalFileName}</FileName>
          {item.status === UploadStatus.FINISHED_WITH_WARNINGS ?
            <DownloadButton onClick={handleDownload} title="Baixar Avisos">
              <FileExcel
                size={20}
                fill={Tokens.ColorUIWarnDim}
                style={{
                  marginLeft: 'auto',
                }}
              />
            </DownloadButton>
          : null}
        </Flex>
      </div>
      <Flex $type="column" $justify="space-between" $align="stretch">
        <Flex $type="row" $justify="space-between" $align="center">
          <span>{item.status}</span>
          {item.status === UploadStatus.ERRORED && <Tooltip value={item.metadata ?? ''} length={25} $size={10} />}
          <span
            style={{
              fontSize: '20px',
              fontWeight: 600,
              color: Tokens.ColorTextPrimary,
            }}>
            {item.status !== UploadStatus.ERRORED ? `${item.percentage}%` : '100%'}
          </span>
        </Flex>
        <ProgressBar
          $percentage={item.percentage}
          $color={
            [UploadStatus.UPLOADING, UploadStatus.UPLOADED, UploadStatus.STARTED].includes(item.status) ?
              Tokens.ColorUIWarn
            : item.status === UploadStatus.FINISHED ?
              Tokens.ColorUIPrimary
            : Tokens.ColorUIDanger
          }
        />
      </Flex>
    </Component>
  )
}
