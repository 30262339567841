import { ApiFuncParam } from '@/modules'

export function buildUrlRequest(url: string, urlParams?: ApiFuncParam | string): string {
  if (!url || !urlParams) return url
  const builtUrlParams = buildUrlParams(urlParams)
  return builtUrlParams ? `${url}${builtUrlParams}` : url
}

function buildUrlParams(urlParams?: ApiFuncParam | string): string | undefined {
  if (!urlParams) return undefined

  if (typeof urlParams === 'string') return urlParams

  if (typeof urlParams === 'object') {
    const searchParams = new URLSearchParams()
    Object.entries(urlParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, String(value))
      }
    })
    return `?${searchParams.toString()}`
  }
  return undefined
}
