import { memo } from 'react'

import { BookmarkOne, Star, Tag } from '@icon-park/react'

import { CreateThemeRequest, SearchResponse } from '@shared/models'

import { Tokens } from '@/assets/tokens'

import { Alert, Api } from '@/modules'

import { useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Selector } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { useCompanyContext } from '../CompanyDetails.context'
import { getDescription } from '../utils'
import { ThemesTable } from './Themes.table'

export const Themes = memo(ThemesComponent)

function ThemesComponent() {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, reloadThemes } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value, binder: valueBinder, reset: resetValue } = useInput()
  const { selectedOption: selectedBrand, binder: brandBinder, reset: resetBrand } = Selector.Binder()
  const { selectedOption: selectedThemeGroup, binder: themeGroupBinder, reset: resetThemeGroup } = Selector.Binder()

  const [isSearchingBrands, searchBrands, searchedBrands] = Api.Query<SearchResponse[]>(
    `brands/search?company=${companyID}&size=10000`,
  )
  const [isSearchingThemeGroups, searchThemeGroups, searchedThemeGroups = []] = Api.Query<SearchResponse[]>(
    `theme-groups/search?company=${companyID}&size=10000`,
  )
  const { isRunning: isCreatingTheme, run: createTheme } = Api.Command<void>(Api.Methods.POST, `themes`)

  function onSubmit(): void {
    const input = {
      companyID,
      value,
      brandInternalID: selectedBrand!.value as number,
      themeGroupInternalID: selectedThemeGroup!.value as number,
    } as CreateThemeRequest
    createTheme<CreateThemeRequest>({
      input,
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Tema cadastrado com sucesso!',
    })
    resetValue()
    resetBrand()
    resetThemeGroup()

    reloadThemes({ page: currentPage })
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Tag}
        healthCheck={company.healthCheck?.isThemesChecked}
        title="Temas Monitorados"
        description={getDescription(company.counts?.themes)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'temas',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="temas"
        backToName={company.name}
        title="Temas Monitorados"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do tema.
          </span>
        }
        icon={Tag}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingTheme}>
          <Flex $type="column" $align="stretch" $gap={24} $fit>
            <Input label="Nome do tema" placeholder="Nome do tema" required {...valueBinder} />
            <Flex $type="row" $justify="flex-start" $align="center" $gap={20}>
              <Selector.Searchable
                {...brandBinder}
                label="Marca"
                options={
                  searchedBrands?.map((brand) => ({
                    text: brand.text,
                    value: brand.value,
                  })) ?? []
                }
                onSearch={(searchParam: string): void => {
                  searchBrands({ name: searchParam })
                }}
                loading={isSearchingBrands}
                required>
                <Selector.Icon value={Star} color={Tokens.ColorUIPrimary} />
              </Selector.Searchable>
              <Selector.Searchable
                {...themeGroupBinder}
                label="Grupo do tema"
                options={searchedThemeGroups.map((themeGroup) => ({
                  text: themeGroup.text,
                  value: themeGroup.value,
                }))}
                onSearch={(searchParam: string): void => {
                  searchThemeGroups({ name: searchParam })
                }}
                loading={isSearchingThemeGroups}
                required>
                <Selector.Icon value={BookmarkOne} color={Tokens.ColorUIPrimary} />
              </Selector.Searchable>
            </Flex>
            <Flex $type="row" $justify="flex-end" $align="center" $gap={20} $fit>
              <Button.Root type="submit" $variant="primary" $size={315} $loading={isCreatingTheme}>
                <Button.Text value="Adicionar" />
              </Button.Root>
            </Flex>
          </Flex>
        </Form>
        <ThemesTable />
      </Modal>
    </>
  )
}
