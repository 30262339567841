import { Tokens } from '@/assets/tokens'
import { Button, Divider, Flex } from '@/components'
import { ColumnType, SkeletonLoader, Table } from '@/components/derived'
import { Layout } from '@/components/layout'
import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { Api } from '@/modules'
import { Edit } from '@icon-park/react'
import { Paged, UserData } from '@shared/models'
import { useEffect, useState } from 'react'

type TableData = {
  name: string
  email: string
  id: string
}

export function Users() {
  const { navigate } = useRouting()

  const [, , companyUsers] = Api.Query<Paged<UserData>>(`/users/`)
  const [tableData, setTableData] = useState<TableData[]>(
    companyUsers?.elements.map((item) => ({
      name: item.name,
      email: item.email,
      id: item.id,
    })) ?? [],
  )
  const columns: ColumnType<TableData>[] = [
    { key: 'name', title: 'Nome', width: 250, align: 'left' },
    { key: 'email', title: 'E-mail', width: 250, align: 'left' },
    {
      key: 'edit',
      width: 40,
      render: (_, item) => (
        <Button.Root $variant="link" onClick={() => navigate(Routes.CreateUser, item.id)}>
          <Button.Icon value={Edit} color={Tokens.ColorTextSecondary} />
        </Button.Root>
      ),
    },
  ]

  useEffect(() => {
    if (companyUsers?.elements) {
      setTableData(
        companyUsers.elements.map((item) => ({
          name: item.name,
          email: item.email,
          id: item.id,
        })),
      )
    }
  }, [companyUsers])

  return (
    <Layout.Login>
      <Layout.Padder>
        <Flex $type="row" $justify="center" $align="center" $gap={24}>
          <Flex $type="column" $justify="center" $align="center">
            <Divider.Root>
              <Divider.Title value="Tabela de Usuários" />
              <Divider.Spacer />
              <Divider.Subtitle value={`${companyUsers?.total ?? 0} Usuários`} />
            </Divider.Root>
            {!companyUsers?.total ?
              <SkeletonLoader />
            : <Table data={tableData} columns={columns} totalPages={1} />}
            <Flex $type="row" $justify="space-between" $align="center" $fit style={{ width: '100%' }}>
              <Button.Root $variant="outlined" onClick={() => navigate(Routes.Assessors)} $size={200}>
                <Button.Text value="Voltar" />
              </Button.Root>
              <Button.Root $variant="primary" onClick={() => navigate(Routes.CreateUser)} $size={200}>
                <Button.Text value="Novo usuário" />
              </Button.Root>
            </Flex>
          </Flex>
        </Flex>
      </Layout.Padder>
    </Layout.Login>
  )
}
