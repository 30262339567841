import { Plus } from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { useRouting } from '@/hooks'

import { Divider, Icon } from '@/components'
import { Layout } from '@/components/layout'

import { Paginator, SkeletonLoader } from '@/components/derived'
import { Routes } from '@/configs'
import { Alert, Api } from '@/modules'
import { CompanyResponse, ReportStatus } from '@shared/models'
import { Filters } from './components/Filters/Filters.component'
import { NewReportFormModal, NewReportModalName } from './components/NewReportForm.component'
import { ReportItem } from './components/ReportItem.component'
import { ReportsContextProvider, useReportsContext } from './Reports.context'
import { ItemsContainer, NewReportButton, ReportsContainer } from './Reports.styles'
import { CompanyDetailsContextProvider } from '../CompanyDetails/CompanyDetails.context'

export function Reports() {
  return (
    <ReportsContextProvider>
      <ReportsComponent />
    </ReportsContextProvider>
  )
}

export function ReportsComponent(): JSX.Element {
  const { companyID, changeQueryParam, navigate } = useRouting()
  const { loadingReports, reportItems, reports } = useReportsContext()
  const sortedReportItems = reportItems.sort((a, b) => {
    const priorityOrder: Record<ReportStatus, number> = {
      [ReportStatus.STARTED]: 1,
      [ReportStatus.UPLOADED]: 2,
      [ReportStatus.FINISHED]: 3,
      [ReportStatus.ERRORED]: 3,
    }

    return priorityOrder[a.status] - priorityOrder[b.status]
  })

  const [isLoadingCompany, , company] = Api.Query<CompanyResponse>(`companies/${companyID}`)

  const { sendAlert } = Alert.Hook()

  function openFormModal(): void {
    changeQueryParam({
      param: 'modal',
      value: NewReportModalName,
      replaceAll: true,
    })
  }

  if (company && !company.healthCheck!.isHealthy) {
    sendAlert({
      type: 'error',
      title: 'Erro no manual',
      message: 'Empresa está com manual incompleto: Favor revisar o manual!',
    })
    navigate(Routes.CompanyDetails.path, companyID!)
  }

  return (
    <CompanyDetailsContextProvider>
      <Layout.Root>
        <ReportsContainer>
          <Filters />
          <Divider.Root>
            <Divider.Title value="Histórico de Relatórios" />
            <Divider.Spacer />
            <Divider.Subtitle value={`${reports?.elements.length ?? 0} Relatórios`} />
          </Divider.Root>
          <span>Pressione F5 para atualizar a situação do processamento.</span>
          <ItemsContainer>
            {isLoadingCompany || loadingReports ?
              <SkeletonLoader />
            : <>
                <NewReportButton onClick={openFormModal}>
                  <Icon value={Plus} color={Tokens.ColorWhite} size={16} />
                  <span>Gerar Relatório</span>
                </NewReportButton>
                {sortedReportItems?.map((element) => <ReportItem key={element.id} report={element} />)}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '1rem 0',
                  }}>
                  <Paginator totalPages={reports?.pages} />
                </div>
              </>
            }
          </ItemsContainer>
          <NewReportFormModal />
        </ReportsContainer>
      </Layout.Root>
    </CompanyDetailsContextProvider>
  )
}
