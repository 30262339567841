import { Tokens } from '@/assets/tokens'
import { Button, Flex, Form, Input, Switch } from '@/components'
import { useInput, useRouting } from '@/hooks'
import { Alert } from '@/modules'
import { useCompanyContext } from '@/pages/CompanyDetails/CompanyDetails.context'
import { Str } from '@/utils/strings'
import { Close } from '@icon-park/react'
import { UpdateCompanyClipperRequest } from '@shared/models'
import { useClippersContext } from '../../CompanyClippers.context'
import { Container, Overlay } from './EditModal.styles'
import { useEffect } from 'react'

export function EditModal(): JSX.Element | null {
  const { companyID, currentPage } = useRouting()
  const { currentClipper, isUpdatingCompanyClipper, setCurrentCompanyClipper, updateCompanyClipper } =
    useClippersContext()

  const { reloadCompanyClippers } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: acronym, binder: acronymBinder, reset: resetAcronym } = useInput()
  const { value: apiKey, binder: apiKeyBinder, reset: resetApiKey } = useInput()
  const { value: url, binder: urlBinder, reset: resetURL } = useInput()
  const monitorAssociatedThemesBinder = Switch.Hook(false)

  useEffect(() => {
    if (!currentClipper) return
    resetAcronym(currentClipper.acronym)
    resetApiKey(currentClipper.apiKey)
    resetURL(currentClipper.urlFix)
    if (monitorAssociatedThemesBinder.isChecked !== currentClipper.monitorAssociatedThemes) {
      monitorAssociatedThemesBinder.toggle()
    }
  }, [currentClipper])

  if (!companyID || !currentClipper) return null

  function onSubmit(): void {
    const clipperID = Str.from(currentClipper?.id)
    if (clipperID.isBlank) {
      return sendAlert({ type: 'error', title: 'Campo obrigatório', message: 'Clipper é obrigatório' })
    }

    const input = {
      // acronym: Str.from(acronym).value,
      // apiKey: Str.from(apiKey).value,
      // url: Str.from(url).value,
      monitorAssociatedThemes: monitorAssociatedThemesBinder.isChecked,
    } as UpdateCompanyClipperRequest
    if (acronym) input.acronym = acronym
    if (apiKey) input.apiKey = apiKey
    if (url) input.url = url
    updateCompanyClipper<UpdateCompanyClipperRequest>({
      input,
      binds: [
        ['companyID', companyID!],
        ['companyClipperID', clipperID.val],
      ],
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Clipadora editada com sucesso!',
    })
    resetAcronym()
    resetApiKey()
    resetURL()

    reloadCompanyClippers(currentPage)
    setCurrentCompanyClipper(undefined)
  }

  return (
    <Overlay>
      <Container>
        <Flex $type="row" $justify="space-between" $align="center" $fit>
          <h3>Atualização da Clipadora</h3>
          <Button.Root $variant="link" onClick={() => setCurrentCompanyClipper(undefined)}>
            <Button.Icon value={Close} color={Tokens.ColorTextPrimary} />
          </Button.Root>
        </Flex>
        <Form onSubmit={onSubmit} controlParam={isUpdatingCompanyClipper}>
          <Flex $type="row" $justify="space-between" $align="center" $gap={24} $fit>
            <Input label="Acrônimo" placeholder="Acrônimo" {...acronymBinder} required />
            <Input label="Chave da API" placeholder="Chave da API" {...apiKeyBinder} />
          </Flex>
          <Flex $type="row" $justify="space-between" $align="center" $gap={24} $fit>
            <Input label="URL da API" placeholder="URL da API" {...urlBinder} />
          </Flex>
          <Flex $type="row" $justify="space-between" $align="center" $gap={20} $fit>
            <Switch.Root
              label={monitorAssociatedThemesBinder.isChecked ? 'Monitorar tudo' : 'Monitorar apenas marca(s)'}
              {...monitorAssociatedThemesBinder}
            />
            <Button.Root type="submit" $variant="primary" $size={310} $loading={isUpdatingCompanyClipper}>
              <Button.Text value="Salvar" />
            </Button.Root>
          </Flex>
        </Form>
      </Container>
    </Overlay>
  )
}
