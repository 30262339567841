import { Report, ReportStatus, ReportStatusMessage, ReportType } from '@shared/models'

import DailyIcon from '@/assets/images/daily_report_icon.svg'
import MonthlyIcon from '@/assets/images/monthly_report_icon.svg'

import { Flex, Icon } from '@/components'

import { Tokens } from '@/assets/tokens'
import { Download } from '@icon-park/react'
import {
  DownloadButton,
  DownloadContainer,
  ReportButton,
  ReportIcon,
  ReportName,
  ReportStatusLine,
  StatusContainer,
  StatusText,
} from './styles'
import { Api } from '@/modules'

export enum StatusToPercentage {
  UPLOADED = 25,
  STARTED = 50,
  FINISHED = 100,
  ERRORED = StatusToPercentage.FINISHED,
}

export function ReportItem({ report }: { report: Report }): JSX.Element {
  const [getReportById] = Api.PromiseQuery<Report>(`companies/${report.companyID}/reports/details`)

  async function getFileLinkByRerportId(id: string): Promise<string | undefined> {
    if (!id) return
    const [foundReport] = await getReportById({ reportID: id })
    return foundReport?.fileLink
  }

  return (
    <ReportButton>
      <Flex $type="row" $justify="space-between" $align="flex-start" $gap={5}>
        <Flex $type="row" $justify="flex-start" $align="center" $gap={5}>
          <ReportIcon alt="report-type" src={report.type === ReportType.DAILY ? DailyIcon : MonthlyIcon} />
          <ReportName title={report.name}>{report.name}</ReportName>
        </Flex>
      </Flex>
      <StatusContainer>
        {<StatusText>{ReportStatusMessage[report.status]}</StatusText>}
        <ReportStatusLine $status={report.status} $percentage={StatusToPercentage[report.status]} />
        <span
          style={{
            fontSize: '20px',
            fontWeight: 600,
            color: Tokens.ColorTextPrimary,
          }}>
          {`${StatusToPercentage[report.status]}%`}
        </span>
        {report.status === ReportStatus.FINISHED && (
          <DownloadContainer>
            <StatusText>{report.startDate}</StatusText>
            <DownloadButton
              onClick={async () => {
                if (!report.fileLink) report.fileLink = await getFileLinkByRerportId(report.id)
                if (report.fileLink) window.location.href = report.fileLink
                return false
              }}
              target="_blank">
              <Icon value={Download} color={Tokens.ColorUIPrimary} size={16} />
            </DownloadButton>
          </DownloadContainer>
        )}
      </StatusContainer>
    </ReportButton>
  )
}
