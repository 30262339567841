import axios, { AxiosResponse } from 'axios'

import { useCallback, useState } from 'react'

import { Alert } from '@/modules'

import { Routes } from '@/configs'

import { useRouting } from '@/hooks'

import { useBaseAPI } from './baseApi.hook'

export type Headers = { [key: string]: string }

export type UploadProgress = {
  loaded: number
  total?: number
}

export type RequestOptions = {
  headers?: Headers
  onUploadProgress?: (progressEvent: UploadProgress) => void
}

export enum CommandMethods {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface UseCommandOutput<T> {
  isRunning: boolean
  data?: T
  error?: Error
  run: <I>(input: RunCommandInput<I, T>, options?: RequestOptions) => void
  abort: () => void
}

type Bind = [string, string]

export interface RunCommandInput<I, O> {
  input?: I
  binds?: Bind[]
  onSuccess?: (data: O) => void
  onFail?: (error: Error) => void
}

export function useCommand<T>(method: CommandMethods, url: string): UseCommandOutput<T> {
  const { instance, canceler } = useBaseAPI()
  const { navigate } = useRouting()

  const [isRunning, setRunning] = useState<boolean>(false)
  const [data, setData] = useState<T | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const { sendAlert } = Alert.Hook()

  const run = useCallback(
    <I>({ input, binds, onSuccess, onFail }: RunCommandInput<I, T>, options?: RequestOptions): void => {
      setRunning(true)

      let finalUrl = url
      if (binds?.length) {
        binds.forEach((bind: Bind) => {
          finalUrl = finalUrl.replace(`:${bind[0]}`, bind[1])
        })
      }

      instance({
        method,
        url: finalUrl,
        data: input,
        headers: {
          ...options?.headers,
        },
        onUploadProgress: options?.onUploadProgress,
      })
        .then(({ data }: AxiosResponse<T>) => {
          setData(data)
          if (onSuccess) onSuccess(data)
        })
        .catch((error: Error) => {
          setError(error)
          setData(undefined)
          if (axios.isAxiosError(error)) {
            const response = error.response!
            if (response.status === 400) {
              sendAlert({
                type: 'error',
                title: 'Erro de requisição',
                message: 'A requisição enviada é inválida, verifique-a e tente novamente!',
              })
              if (onFail) onFail(error)
              return
            }
            if (response.status === 401) {
              sendAlert({
                type: 'error',
                title: 'Erro de autenticação',
                message: 'Vocês não está autenticado, efetue o login e tente novamente!',
              })
              return navigate(Routes.Login.path)
            }
            if (response.status === 403) {
              sendAlert({
                type: 'error',
                title: 'Erro de autorização',
                message: 'Você não possui permissões para acessar esse recurso!',
              })
              return navigate(Routes.Home.path)
            }
            if (response.status === 404) {
              sendAlert({
                type: 'error',
                title: 'Recurso não encontrado',
                message: 'Ocorreu um erro ao buscar o recurso solicitado, verifique a requisição e tente novamente',
              })
              return navigate(Routes.Home.path)
            }
            if (response.status === 409) {
              sendAlert({
                type: 'error',
                title: 'Recurso já existe',
                message:
                  'Não foi possível concluir a solicitação porque o recurso já existe. ' +
                  'Por favor, revise as informações e tente novamente',
              })
              if (onFail) onFail(error)
              return
            }
            sendAlert({
              type: 'error',
              title: 'Erro inesperado',
              message:
                'Ocorreu um erro inesperado ao efetuar a requisição, tente novamente mais tarde ou contate o suporte!',
            })
            if (onFail) onFail(error)
          }
        })
        .finally(() => {
          setRunning(false)
        })
    },
    [],
  )

  return {
    isRunning,
    data: data,
    error,
    run,
    abort: canceler.abort,
  }
}
