import { Api } from '@/modules'
import { CommandMethods, RequestOptions, RunCommandInput } from '@/modules/api/useCommand'
import { CompanyClipperResponse, SearchResponse, UpdateCompanyClipperRequest } from '@shared/models'
import { createContext, useContext, useState } from 'react'

interface ClippersContextOutput {
  clippers?: SearchResponse[]
  currentClipper?: CompanyClipperResponse
  isSearchingClippers: boolean
  isUpdatingCompanyClipper: boolean
  setCurrentCompanyClipper: (clipper: CompanyClipperResponse | undefined) => void
  updateCompanyClipper: <I>(input: RunCommandInput<I, UpdateCompanyClipperRequest>, options?: RequestOptions) => void
}

const ClippersContext = createContext<ClippersContextOutput>({} as ClippersContextOutput)

export function ClippersContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [currentClipper, setCurrentCompanyClipper] = useState<CompanyClipperResponse | undefined>()
  const [isSearchingClippers, , clippers] = Api.Query<SearchResponse[]>(`clippers`)
  const { isRunning: isUpdatingCompanyClipper, run: updateCompanyClipper } = Api.Command<UpdateCompanyClipperRequest>(
    CommandMethods.PATCH,
    `companies/:companyID/clippers/:companyClipperID`,
  )
  return (
    <ClippersContext.Provider
      value={{
        clippers,
        currentClipper,
        isSearchingClippers,
        isUpdatingCompanyClipper,
        setCurrentCompanyClipper,
        updateCompanyClipper,
      }}>
      {children}
    </ClippersContext.Provider>
  )
}

export function useClippersContext(): ClippersContextOutput {
  const context = useContext(ClippersContext)
  if (!context) throw new Error('useClippersContext deve ser chamado dentro do contexto correto')
  return context
}
